import countUp from "@/views/my-components/widget/countUp.vue";
var zh = require("../../../../libs/zh.json");
export default {
  name: "card-bar",
  components: {
    countUp
  },
  props: {
    id: {
      type: String,
      default: "countup"
    },
    title: {
      type: String,
      default: "-"
    },
    value: {
      type: Number,
      default: 0
    },
    decimalPlaces: {
      type: Number,
      default: 0
    },
    prefix: String,
    suffix: String,
    status: {
      type: String,
      default: "up"
    },
    compare: String,
    since: {
      type: String,
      default: "与上周相比"
    }
  },
  data() {
    return {
      series: [{
        data: []
      }],
      chartOptions: {
        colors: ["#47a1ff"],
        chart: {
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        tooltip: {
          fixed: {
            enabled: false
          },
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return "";
              }
            }
          },
          marker: {
            show: false
          }
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        data: [2, 5, 7, 4, 9, 7, 5, 2, 3, 5, 6]
      }];
    }
  },
  mounted() {
    this.init();
  }
};